import {makeRandomIdentifier} from "@utility";

export interface INodeMap {
	[id: string]: NanoNode;
}

export enum BlockHtmlType {
	DIV = 0,
	INPUT = 1,
	TEXTAREA = 2,
	UL = 3,
	LI = 4,
	BUTTON = 5,
	VIDEO = 6,
	SPAN = 7,

	CUSTOM = 100,
}

export interface IGlue {
	fdefs: {
		"remote": string; args: string[]
	}[];
}

export type GlueError = {
	"error": string;
}

export function blockTag(block: BlockHtmlType, node: NanoNode): string {
	switch (block) {

		case BlockHtmlType.DIV:
			return "div";

		case BlockHtmlType.INPUT:
			return "input";

		case BlockHtmlType.TEXTAREA:
			return "textarea";

		case BlockHtmlType.UL:
			return "ul";

		case BlockHtmlType.SPAN:
			return "span";

		case BlockHtmlType.LI:
			return "li";

		case BlockHtmlType.BUTTON:
			return "button";

		case BlockHtmlType.VIDEO:
			return "video";

		case BlockHtmlType.CUSTOM:
			if (node && node.customHtmlType && node.customHtmlType.length > 0) {
				return node.customHtmlType;
			}
			return "div";
	}
}

export enum ControlType {
	Color = 0,
	Thickness = 1,
	Real = 2,
	String = 3,
}

export enum InputType {
	Number,
	String,
	Object
}


export type NanoNode = {
	id: string;
	name?: string; // DEPRICATED
	className: string;
	text: string;

	contentHtml: string;
	isContentBlock: boolean;

	clickAction: string; // Click event handler
	enableAction: string; // Enable/Disable event handler
	classAction: string; // Add classes from this object

	repeatAction: string;
	repeatBinding: string; // For array for loops

	model: string;

	htmlType: BlockHtmlType;
	customHtmlType: string;

	children: NanoNode[];
	isRootNode: boolean;

	attrs: { [key: string]: string } | null;

	focusGroup?: string;
}

export interface IBlockView {
	name: string;
	stylesheet: string;
	minWidth: number;
	maxWidth: number;
}

// Data required for object initialization
export interface Input {
	readonly id: string;
	name: string;  //must be a valid identifier
	description: string;
	type: InputType;
}

// Block settings
export interface IThemeVariable {
	readonly id: string;
	name: string;
	description: string;
	required: boolean;
	defaultValueInitializor: string;
	controlType: ControlType;
	controlTypeParameters: any;
}

export interface IConfigurationValue {
	referenceId: string;
	value: string;
}

export interface ICommonAppConfig {
	title?: string;
	favicon?: string;
}

export interface AppConfig extends ICommonAppConfig {
	settings: IConfigurationValue[];
	inputs: IConfigurationValue[];
	privateInputs?: IConfigurationValue[];
}

export interface ClientAppConfig extends ICommonAppConfig {
	settings: { [key: string]: string };
	inputs: { [key: string]: string };
}

export interface ITest extends AppConfig {
	name: string;
	description: string;
	webhook: string;
	webhookConfig: string;
}

export interface IOutputData extends Input {

}

export interface IExternalLibrary {
	url: string;
}

export type BlueprintType = "client" | "server" | "style" | "structure"

export type BlueprintReference = {
	id: number;
	ptr: string;
	code: string;
	type: BlueprintType;
	name: string;
	description: string;
}

export type Resource = {
	blob: string;
	contentType: string;
	file: string;
	url: string;
}

export type ClientNanosite = {

	readonly id: number; // must be a valid css identifier
	readonly name: string;

	root: NanoNode;
	stylesheet: string;
	js: string;
	views: IBlockView[];
	variables: Input[];
	settings: IThemeVariable[];
	outputs: IOutputData[];
	examples: ITest[];
	libraries: IExternalLibrary[];
	blueprints?: BlueprintReference[];
}

export type Nanosite = ClientNanosite & {
	backend: string;
	resources?: Resource[];
	readonly group: string;
	readonly initialized: boolean;
	readonly modified: number;
	privateInputs: Input[];
}

export function makeDefaultNode(children: NanoNode[] = []): NanoNode {
	return {
		id: makeRandomIdentifier(),
		text: "",
		customHtmlType: "",
		contentHtml: "",
		isContentBlock: false,
		htmlType: BlockHtmlType.DIV,
		className: "",
		isRootNode: false,
		classAction: "",   // v-bind:class attribute
		repeatBinding: "", // part of v-for attribute
		repeatAction: "",  // part of v-for attribute
		enableAction: "",  // v-if attribute
		clickAction: "",   // v-on:click attribute
		model: "",         // v-model attribute (on input fields)
		children: children,
		attrs: {}
	};
}

export function makeDefaultNanosite(blockId: number, name: string): Nanosite {

	return {
		id: blockId,
		name: name,
		group: "",
		initialized: false,
		backend: "",
		js: "const data = {}\n" +
			"function init() { }\n" +
			"const methods = {}",
		stylesheet: "",
		root: {
			id: makeRandomIdentifier(),
			name: "root node",
			contentHtml: "",
			text: "",
			isContentBlock: false,
			htmlType: BlockHtmlType.DIV,
			className: "root",
			isRootNode: true,
			enableAction: "",
			clickAction: "",
			repeatBinding: "", // For array for loops
			repeatAction: "",
			classAction: "",
			model: "",
			customHtmlType: "",
			children: [],
			attrs: {},
		},
		views: [],
		variables: [],
		settings: [],
		outputs: [],
		examples: [],
		libraries: [],
		modified: 0,
		privateInputs: [],
	};
}

export function traverse(node: NanoNode, collection: INodeMap = {}) {

	collection[node.id] = node;
	for (let child of node.children) {
		traverse(child, collection);
	}
	return collection;
}


export function createFocusGroupLookup(node: NanoNode, groups: NanoNode[]) {

	if (node.isRootNode)
		groups.push(node);

	if (node.focusGroup)
		groups.push(node);

	for (let child of node.children)
		createFocusGroupLookup(child, groups);
}
