import {getHashCode} from "./strings";

function checkNodeClassName(node: Element, name: string): boolean {
	return node.classList.contains(name);
}

export function findChildWithClassName(node: Element, name: string): Element | null {

	if (checkNodeClassName(node, name)) {
		return node;
	}

	for (let i = 0; i < node.children.length; ++i) {
		const child = node.children[i];
		const result = findChildWithClassName(child, name);
		if (result != null) {
			return result;
		}
	}

	return null;
}

const registry = new Map<string, Promise<boolean>>();

async function loadScriptFromUrl(url: string): Promise<boolean> {

	const scriptId = "script_" + Math.abs(getHashCode(url));
	const previous = registry.get(scriptId);
	if (previous != null)
		return previous;

	return await new Promise((resolve) => {

		const script = document.createElement("script");
		script.src = url;
		script.id = scriptId;
		script.async = true;

		script.onload = async () => {
			clearTimeout(timeout);
			resolve(true);
		};

		document.head.appendChild(script);
		const timeout = setTimeout(() => {
			resolve(false);
		}, 12000);
	});
}

export function loadScriptDynamically(url: string): Promise<boolean> {

	// Monaco Adds this, and it confuses scripts that can be AMD-module loaded
	const define = window["define"];
	let defineResolver = window["define_resolver"];
	window["define_offset"] = (window["define_offset"] || 0) + 1;

	if (define) {
		window["define"] = undefined;
		const defineResolved = new Promise((resolve => {
			defineResolver = window["define_resolver"] = resolve;
		}));
		setTimeout(async () => {
			await defineResolved;
			window["define"] = define;
			window["define_offset"] = undefined;
			window["define_resolver"] = undefined;
		});
	}

	const promise = loadScriptFromUrl(url);

	setTimeout(async () => {
		try {
			await promise;
		} finally {
			window["define_offset"]--;
			if (window["define_offset"] === 0 && defineResolver) {
				defineResolver();
			}
		}
	});

	return promise;
}
