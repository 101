import BalloonEditor from "@ckeditor/ckeditor5-build-balloon";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import {filter, map, Observable, Subject} from "rxjs";

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.css";

let vueIsInitialized = false;

type VueVMPackage<T> = { value: T; vm: Vue }

const errorStream = new Subject<VueVMPackage<Error>>();
const warningStream = new Subject<VueVMPackage<string>>();

export interface IVueEditorConfig {
	controller: any;
	options: any;
}

export function getVueEditorConfig(): IVueEditorConfig {
	return {
		controller: BalloonEditor,
		options: {}
	};
}

async function initializeVue() {
	if (vueIsInitialized) {
		return;
	}

	vueIsInitialized = true;

	Vue.config.productionTip = false;

	Vue.use(Vuetify);
	Vue.use(CKEditor);

	Vue.config.errorHandler = (err: Error, vm: Vue) => {
		errorStream.next({value: err, vm: vm.$root});
	};

	Vue.config.warnHandler = (msg: string, vm: Vue) => {
		console.warn("VUE WARNING:", msg);
		warningStream.next({value: msg, vm: vm});
	};
}

export class VueVM {

	get errors(): Observable<Error> {
		return errorStream
			.pipe(filter(x => x.vm === this.vue))
			.pipe(map(x => x.value));
	}

	get warnings(): Observable<string> {
		return warningStream
			.pipe(filter(x => x.vm === this.vue))
			.pipe(map(x => x.value));
	}

	constructor(readonly vue: Vue) {

	}

	dispose() {
		this.vue.$destroy();
	}
}

export async function instantiateVue(id: string, data: any, methods: any, watch: any, computed: any): Promise<VueVM> {

	await initializeVue();
	const vue = new Vue({
		vuetify: new Vuetify(),
		data: data,
		methods: methods,
		watch: watch,
		computed: computed,
	}).$mount("#" + id);

	return new VueVM(vue);
}

export function getVueObject(): any {
	return Vue;
}
