import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {ClientComponent} from "./client.component";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
	declarations: [
		ClientComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule
	],
	providers: [],
	bootstrap: [ClientComponent]
})
export class AppModule {
}
