export function getHashCode(str: string): number {

	let hash = 0, i, chr;

	if (str.length === 0) {
		return hash;
	}

	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}

	return hash;
}

const alphabet = "abcdefghjklmnpqrstuvwxyz";

export function makeRandomIdentifier(length: number = 8) {
	let text = "";
	const n = alphabet.length;
	for (let i = 0; i < length; i++) {
		text += alphabet.charAt(Math.floor(Math.random() * n));
	}
	return text;
}

export function makeUUID() {
	let result, i, j;
	result = "";
	for (j = 0; j < 32; j++) {
		if (j == 8 || j == 12 || j == 16 || j == 20) {
			result = result + "-";
		}
		i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
		result = result + i;
	}
	return result;
}
