<div class="loading" *ngIf="loading">
	<div class="info">
		<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
		<h1>Loading, please wait</h1>
	</div>
</div>

<div class="frozen-screen" *ngIf="rpcInProgress"></div>

<div class="render-container">
</div>

<div class="error" *ngIf="error">
	<h1>Oops. Something went wrong :(</h1>
	<p>{{errorMessage}}</p>
</div>
