function getCookie(cookieName: string): string | null {
	if (!document.cookie) {
		// No cookies are present at all
		return null;
	}

	const cookies: string[] = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		const [name, value] = cookies[i].trim().split('=');

		if (name === cookieName) {
			return value;
		}
	}

	// Cookie with the specified name not found
	return null;
}

function base64UrlToBase64(input: string): string {
	// Replace non-url compatible chars with base64 standard chars
	input = input.replace(/-/g, '+').replace(/_/g, '/');

	// Pad out with standard base64 required padding characters
	const pad = input.length % 4;
	if (pad) {
		if (pad === 1) {
			throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
		}
		input += new Array(5 - pad).join('=');
	}

	return input;
}

function decodeJWT(token: string): {
	claims: {
		[key: string]: any
	} | null,
	expires: number | null
} {
	const parts = token.split('.');
	if (parts.length !== 3) {
		throw new Error('InvalidTokenError: The token must have three parts');
	}

	const payload = JSON.parse(atob(base64UrlToBase64(parts[1])));
	return {claims: payload.claims, expires: parseInt(payload.expires)};
}

export type RPCSec = { signedIn: false } | {
	signedIn: true; claims: {
		[key: string]: any
	};
}

export type RPCSecUpdate = { rpcSec: RPCSec; expires: number | null; key: string; } | "no-update"

export function processRPCSec(sessionId: number | string, key: string | null): RPCSecUpdate {

	const cookie = getCookie(`rpc-sec-${sessionId}`);
	if (!cookie) {
		return {rpcSec: {signedIn: false}, expires: null, key: null};
	}

	if (cookie === key) {
		return "no-update"
	}

	const {claims, expires} = decodeJWT(cookie);

	return {
		rpcSec: {
			signedIn: true,
			claims: claims
		},
		expires: expires,
		key: cookie
	}
}
