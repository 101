import {VueVM} from "./vueutil";
import {NanositeUserSession} from "./nanosite";

export type NanositeGlobals = {
	setTimeout: (callback: Function, delay: number) => number;
	clearTimeout: (id: number) => void;
	setInterval: (callback: Function, delay: number) => number;
	clearInterval: (id: number) => void;
}

export function getNanositeGlobals(scope: NanositeUserSession, getVM: () => VueVM): NanositeGlobals {

	const clearTimer = window["__zone_symbol__clearTimeout"] || window.clearTimeout;

	const timerMaker = (name: string) => {

		const setTimer = window["__zone_symbol__" + name] || window[name];

		return function (callback: Function, delay: number) {

			let timeoutId = undefined;
			const wrapper = async function () {

				if (!scope.$alive())
					clearTimer(timeoutId);

				try {
					const response = callback();
					if (response instanceof Promise)
						await response;
				} catch (e) {
					scope.$error(e);
				} finally {
					const vm = getVM();
					if (vm) {
						vm.vue.$forceUpdate();
					}
				}
			};

			timeoutId = setTimer(wrapper, delay);
			return timeoutId;
		};
	};

	return {
		setTimeout: timerMaker("setTimeout"),
		setInterval: timerMaker("setInterval"),
		clearTimeout: clearTimer,
		clearInterval: clearTimer
	};
}
